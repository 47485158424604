<template>
  <div class="max-w-content mx-auto">
    <page-content :page="page" />
  </div>
</template>

<script setup lang="ts">
import { pages } from "@/pages";
import Page from "@/interfaces/Page";
import PageContent from "../components/PageContent.vue";
import { Ref, ref, onMounted, onBeforeUpdate } from "vue";

const page: Ref<Page> = ref({ title: "", content: "", active: false });

onMounted(() => {
  const activePage = pages.value.find((page) => page.active);
  if (activePage) page.value = activePage;
});

onBeforeUpdate(() => {
  const activePage = pages.value.find((page) => page.active);
  if (activePage) page.value = activePage;
});
</script>
