<template>
  <div class="lg:w-content w-screen mx-auto py-5 px-2 mt-4">
    <v-snackbar
      location="top"
      v-model="snackbar"
      id="snackbar"
      :color="snackbarColor"
    >
      <v-icon>{{ snackbarIcon }}</v-icon>
      {{ snackbarText }}
    </v-snackbar>
    <h2>Ota yhteyttä</h2>
    <v-form v-model="valid" ref="form">
      <v-text-field
        v-model="email.senderName"
        class="pt-2"
        label="Nimi"
        :rules="required"
        density="comfortable"
        variant="outlined"
        required
      />
      <v-text-field
        v-model="email.senderEmail"
        label="Sähköposti"
        :rules="required"
        variant="outlined"
        density="comfortable"
      />
      <v-text-field
        v-model="email.senderPhone"
        label="Puhelinnumero"
        :rules="required"
        variant="outlined"
        density="comfortable"
      />
      <v-textarea
        v-model="email.message"
        label="Viesti"
        :rules="required"
        variant="outlined"
        density="comfortable"
      />
      <v-btn class="button" @click="validate()">Lähetä</v-btn>
    </v-form>
  </div>
</template>

<script setup lang="ts">
import Email from "@/interfaces/Email";
import { ref, Ref } from "vue";

const emit = defineEmits(["contact"]);

const form: Ref<any> = ref(null);
const email: Ref<Email> = ref({
  senderName: "",
  senderEmail: "",
  senderPhone: "",
  message: "",
});
const valid: Ref<boolean> = ref(false);
const required = [(v: string) => !!v || ""];
const snackbar: Ref<boolean> = ref(false);
const snackbarColor: Ref<string> = ref("");
const snackbarIcon: Ref<string> = ref("");
const snackbarText: Ref<string> = ref("");

const validate = () => {
  form.value.validate();
  if (!valid.value) {
    return;
  }
  emit("contact", email.value, createSnackbar);
};

const createSnackbar = (emailSent: boolean) => {
  console.log(emailSent);
  if (emailSent) {
    form.value.reset();
  }
  snackbarColor.value = emailSent ? "success" : "error";
  snackbarIcon.value = emailSent ? "mdi-check-circle" : "mdi-alert-circle";
  snackbarText.value = emailSent
    ? "Viestin lähetys onnistui"
    : "Viestin lähetys epäonnistui";
  snackbar.value = true;
};
</script>

<style scoped>
#app .button {
  background: var(--main-color);
  color: white;
  font-size: 18px;
  text-transform: unset;
  height: 44px;
  font-weight: 600;
  letter-spacing: normal;
}

#app .button:hover {
  background: #69b2c6;
  font-weight: 600;
}

.v-input .v-input__details {
  background-color: #69b2c6 !important;
}
</style>
