<template>
  <div v-if="page.title !== 'Etusivu'" class="max-w-content mx-auto">
    <page-content :page="page" />
  </div>
  <div v-else>
    <home-banner
      :title="banner.title"
      :content="banner.content"
      :imgSrc="banner.imgSrc"
    />
    <div class="max-w-content mx-auto">
      <page-content :page="page" />
      <contact-box :contactList="contactList" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { previewPage } from "@/pages";
import Page from "@/interfaces/Page";
import HomeBanner from "@/components/HomeBanner.vue";
import PageContent from "../components/PageContent.vue";
import ContactBox from "@/components/ContactBox.vue";
import { Ref, ref, onBeforeUpdate } from "vue";
import { ContactList } from "@/interfaces/Contact";

defineProps<{
  banner: {
    title: string;
    content: string;
    imgSrc?: string;
  };
  contactList: ContactList;
}>();

const page: Ref<Page> = ref({ title: "", content: "", active: true });

onBeforeUpdate(() => {
  page.value = previewPage.value;
});
</script>
