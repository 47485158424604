import { Ref, ref } from "vue";
import Page from "./interfaces/Page";

export const pages: Ref<Page[]> = ref([]);

export const setActivePage = (pageTitle: string) => {
  pages.value = pages.value.map((page) => {
    return {
      title: page.title,
      content: page.content,
      active: page.title.toLowerCase() === pageTitle,
    };
  });
};

export const previewPage: Ref<Page> = ref({
  title: "",
  content: "",
  active: true,
});
