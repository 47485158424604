<template>
  <div class="bg-beige">
    <div class="lg:w-content mx-auto flex h-72 md:h-96">
      <div
        class="hidden md:block lg:w-5/12 md:w-1/2 -ml-24 -mt-4"
        :style="getBackground()"
      ></div>
      <div class="lg:w-7/12 md:w-2/3 pl-4 pb-4 flex flex-column justify-center">
        <h1 class="text-3xl lg:text-4xl text-title mb-2 lg:pb-1">
          {{ title }}
        </h1>
        <p class="text-lg leading-6 sm:leading-7" v-html="content"></p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
interface Banner {
  title: string;
  content: string;
  imgSrc?: string;
}

const props = defineProps<Banner>();

const getBackground = () => {
  if (props.imgSrc) {
    return {
      "background-image": `url(https:${props.imgSrc})`,
    };
  }
};
</script>

<style scoped>
p {
  font-family: Georgia, Times, Times New Roman, serif;
}
</style>
