<template>
  <div>
    <home-banner
      :title="banner.title"
      :content="banner.content"
      :imgSrc="banner.imgSrc"
    />
    <div class="max-w-content mx-auto">
      <page-content :page="homePage" />
      <contact-box :contactList="contactList" />
    </div>
  </div>
</template>

<script setup lang="ts">
import HomeBanner from "@/components/HomeBanner.vue";
import PageContent from "../components/PageContent.vue";
import ContactBox from "@/components/ContactBox.vue";
import { ContactList } from "@/interfaces/Contact";
import Page from "@/interfaces/Page";
import { pages } from "@/pages";
import { onMounted, onUpdated, ref, Ref } from "vue";

defineProps<{
  banner: {
    title: string;
    content: string;
    imgSrc?: string;
  };
  contactList: ContactList;
}>();

const homePage: Ref<Page> = ref({ title: "", content: "", active: true });

onMounted(() => {
  const home = pages.value.find((page) => page.title === "Etusivu");
  if (home) homePage.value = home;
});

onUpdated(() => {
  const home = pages.value.find((page) => page.title === "Etusivu");
  if (home) homePage.value = home;
});
</script>
