<template>
  <div v-if="!mobile" class="px-3 shadow-md z-50" style="height: 110px">
    <div class="max-w-content mx-auto pt-2" style="height: 60px">
      <img
        :src="require('../assets/logo300.png')"
        alt="Terveyden Tuottajat logo"
        class="cursor-pointer"
        @click="navigate('Etusivu')"
      />
    </div>
    <div class="flex max-w-content mx-auto">
      <div
        v-for="page in pages"
        :key="page.title"
        :class="page.active ? activeClass : inactiveClass"
        style="height: 50px"
        @click="navigate(page.title)"
      >
        {{ page.title }}
      </div>
      <a :href="intranetUrl" class="ml-auto mr-4" target="_blank">
        <div
          :class="inactiveClass"
          style="height: 50px; margin-left: auto; margin-right"
        >
          Intranet
          <v-icon class="icon ml-2 text-gray-800"> mdi-open-in-new </v-icon>
        </div>
      </a>
    </div>
  </div>
  <div v-if="mobile">
    <v-app-bar style="box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1)">
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <img
        :src="require('../assets/logo300.png')"
        alt="Terveyden Tuottajat logo"
        class="cursor-pointer scale-75 -translate-x-8"
        @click="navigate('Etusivu')"
      />
    </v-app-bar>
    <v-navigation-drawer v-model="drawer">
      <v-list>
        <v-list-item
          v-for="page in pages"
          :key="page.title"
          @click="navigate(page.title)"
        >
          <v-list-item-title>{{ page.title }}</v-list-item-title>
        </v-list-item>
        <a :href="intranetUrl" class="ml-auto mr-4" target="_blank">
          <v-list-item>
            <v-list-item-title
              >Intranet
              <v-icon class="icon ml-2 text-gray-800">
                mdi-open-in-new
              </v-icon></v-list-item-title
            >
          </v-list-item>
        </a>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script setup lang="ts">
import { pages } from "@/pages";
import { ref, Ref } from "vue";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify/lib/framework.mjs";

const router = useRouter();
const { mobile } = useDisplay();
const drawer: Ref<boolean> = ref(false);

const baseClass =
  "px-2 border-b-4 rounded flex place-items-center cursor-pointer lg:hover:border-hover";
const activeClass = baseClass + " border-main";
const inactiveClass = baseClass + " border-white";
const intranetUrl = process.env.VUE_APP_INTRA_URL;

const navigate = (url: string) => {
  if (url === "Etusivu") {
    router.push("/");
  } else if (url === "Ota yhteyttä") {
    router.push("/ota-yhteytta");
  } else {
    router.push(`/${url}`.toLowerCase());
  }
};
</script>
