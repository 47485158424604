import { setActivePage } from "@/pages";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "./views/HomeView.vue";
import PageView from "./views/PageView.vue";
import ContactView from "./views/ContactView.vue";
import PagePreview from "./views/PagePreview.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    props: true,
  },
  {
    path: "/:title".toLowerCase(),
    name: "page",
    component: PageView,
  },
  {
    path: "/ota-yhteytta",
    name: "contact",
    component: ContactView,
  },
  {
    path: "/preview/:id",
    name: "preview",
    component: PagePreview,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from) => {
  if (to.name === "home") {
    setActivePage("etusivu");
  } else if (to.name === "contact") {
    setActivePage("ota yhteyttä");
  } else {
    setActivePage(to.path.substring(1));
  }
});

export default router;
